import React, {RefObject, useContext, useEffect, useRef, useState} from 'react';
import {Separator} from './proposalViewDataFields/ProposalViewDataDefinition';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, Message, Toast} from '@sabre/spark-react-core';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {AuthenticationContext} from '@scm/authentication/provider/AuthenticationProvider';
import jwt_decode from 'jwt-decode';
import {UsersRoles} from '@scm/components/utils/usersRoles';
import ProposalActionModal from '../../../components/ProposalActionModal';
import {Configuration, ProposalsApi} from '../../../generated/proposal';
import {proposalApiBaseLink} from '../../../assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {openToast} from '@scm/components/messaging/openToast';
import styled from 'styled-components';
import parse from 'html-react-parser';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';
import {useNavigate} from 'react-router-dom';
import InlineButton from '@scm/components/inline-button/InlineButton';
import {middleware} from '@scm/authentication/middleware/middlewareConfig';
import colors from '@scm/components/assets/colors';

const getMissingItemsForApproval = async (proposalId?: string) =>
  new ProposalsApi(
    new Configuration({basePath: proposalApiBaseLink, accessToken: getAccessToken() || '', middleware: middleware})
  ).getMissingItemsForApproval({id: proposalId || ''});

const ProposalViewButtons = ({
  proposalId,
  proposalName,
  status,
  setLoading,
  setIsApproved,
  onboardingId,
}: {
  proposalId?: string;
  proposalName?: string;
  status?: string;
  setLoading?: (flag: boolean) => void;
  setIsApproved: (flag: boolean) => void;
  onboardingId?: string;
}) => {
  if (status === 'approved') return null;

  const toastDuration = 3;
  const {formatMessage} = useIntl();
  const buttonRef = useRef();
  const [openError, toggleOpenError] = useState(false);
  const [missingItemsForProposal, setMissingItemsForProposal] = useState<string[]>([]);
  const [isApproveRejectButtonsDisabled, setIsApproveRejectButtonsDisabled] = useState<boolean>(true);

  const {accessToken} = useContext(AuthenticationContext);
  const {roles} = accessToken ? jwt_decode<{roles: string}>(accessToken) : {roles: ''};
  const canDelete = roles.includes(UsersRoles['ROLE_SABRE_ADMIN']) || roles.includes(UsersRoles['ROLE_ADP']);

  const [actionName, setActionName] = useState<string>('');
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = (): void => setModalOpen(true);
  const reviewStatus = 'review';

  const clickHandler = async (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const target = evt.target as HTMLButtonElement;
    const action = (
      {
        Review: 'review',
        Reject: 'reject',
        Approve: 'approve',
        Delete: 'deleteProposal',
      } as {[key: string]: string}
    )[target.textContent as string];
    setActionName(action);

    if (action === reviewStatus) {
      try {
        await new ProposalsApi(
          new Configuration({
            basePath: proposalApiBaseLink,
            accessToken: getAccessToken(),
            middleware,
          })
        ).review({
          id: proposalId,
        });

        setLoading && setLoading(true);
        openToast(
          formatMessage({id: 'proposalDetails.changeStatus.title'}),
          formatMessage(
            {
              id: 'proposalDetails.changeStatus.message',
            },
            {id: proposalId}
          ),
          ToastType.POSITIVE,
          'spark-icon-check'
        );
      } catch (err) {
        toggleOpenError && toggleOpenError(true);
      }
    } else {
      handleOpen();
    }
  };

  useEffect(() => {
    getMissingItemsForApproval(proposalId)
      .then(missingItems => {
        setMissingItemsForProposal(missingItems);
        setIsApproveRejectButtonsDisabled(!!missingItems.length);
      })
      .catch(() => toggleOpenError(true));
  }, []);

  const isApproveButtonVisible = () => status !== 'Approved' && status !== 'Rejected' && status !== 'Obsolete';

  const hrefOnboardingLink = `${InternalUrls.ProposalsCertificationOnboardings}/${onboardingId}`;
  const hrefMail = '<a href= "mailto:scm-management@sabre.com">scm-management@sabre.com</a>';
  const navigate = useNavigate();

  return (
    <>
      <Separator className="spark-mar-l-2 spark-mar-b-1 spark-mar-t-2 spark-pad-t-1 spark-bold spark-large">
        <FormattedMessage id="proposalDetails.proposalApproval" />
      </Separator>
      {missingItemsForProposal.includes('Missing seller id in proposal') && (
        <MessageContainer>
          <Message
            status="error"
            role="alert"
            type="page"
            title={formatMessage({id: 'proposalDetails.missingSeller.title'})}
            content={
              parse(
                `
                    <p className="spark-mar-b-0">
                        ${formatMessage({
                          id: 'proposalDetails.missingSeller.message.line1'
                        })}
                    </p>
                    <ul className="spark-mar-b-0">
                        <li className="spark-mar-b-0 spark-mar-t-.5">
                            ${formatMessage({
                              id: 'proposalDetails.missingSeller.message.line2'
                            })}
                        </li>
                        <li className="spark-mar-b-0 spark-mar-t-.5">
                            ${formatMessage({
                              id: 'proposalDetails.missingSeller.message.line3'
                            })}
                        </li>
                        <li className="spark-mar-b-0 spark-mar-t-.5">
                            ${formatMessage({
                              id: 'proposalDetails.missingSeller.message.line4'
                            })}
                        </li>
                        <li className="spark-mar-b-0 spark-mar-t-.5">
                            ${formatMessage({
                              id: 'proposalDetails.missingSeller.message.lineMail'
                              }, {hrefMail}
                            )}
                        </li>
                        <li className="spark-mar-b-0 spark-mar-t-.5">
                            ${formatMessage({
                              id: 'proposalDetails.missingSeller.message.line5'
                            })}
                        </li>
                    </ul>`
              ) as string
            }
            titleHeadingLevel={2}
          />
        </MessageContainer>
      )}
      {missingItemsForProposal.includes(`Missing seller's developer users`) && (
        <MessageContainer>
          <Message
            status="error"
            role="alert"
            type="page"
            title={formatMessage({id: 'proposalDetails.missingSellerDevelopers.title'})}
            content={
              parse(
                `
                    <p className="spark-mar-b-0">${formatMessage({
                      id: 'proposalDetails.missingSellerDevelopers.message.line1',
                    })}</p>
                    <p>${formatMessage({id: 'proposalDetails.missingSeller.message.lineMail'}, {hrefMail})}</p>
                    `
              ) as string
            }
            titleHeadingLevel={2}
          />
        </MessageContainer>
      )}
      <ButtonLine className="spark-mar-l-2 spark-mar-t-2 park-btn-group spark-btn-group--left spark-flex flex-items-xs-between">
        <div>
          {status !== 'InReview' && (
            <InlineButton
              id="proposalDetails.review"
              clickHandler={clickHandler}
              className="neutral spark-mar-r-1 spark-mar-b-1"
            />
          )}
          {isApproveButtonVisible() && (
            <InlineButton
              id="proposalDetails.approve"
              clickHandler={clickHandler}
              className="spark-mar-r-1 spark-mar-b-1"
              disabled={isApproveRejectButtonsDisabled}
            />
          )}
          {status !== 'Rejected' && (
            <InlineButton
              id="proposalDetails.reject"
              clickHandler={clickHandler}
              className={`spark-mar-r-1 spark-mar-b-1 ${isApproveRejectButtonsDisabled ? '' : 'error'}`}
              disabled={isApproveRejectButtonsDisabled}
            />
          )}
          {!!onboardingId && (
            <Button
              secondary
              size={ButtonSize.SMALL}
              role="link"
              tabIndex={0}
              ariaLabel={`Open onboarding request page`}
              onClick={() => navigate(hrefOnboardingLink)}
            >
              <FormattedMessage id="proposalDetails.onboardingRequest" />
            </Button>
          )}
        </div>
        {canDelete && status !== 'Obsolete' && status !== 'Approved' && (
          <>
            <InlineButton
              id="proposalDetails.delete"
              clickHandler={clickHandler}
              className="spark-mar-r-1 spark-mar-b-1 delete"
            />
          </>
        )}
        {actionName && actionName !== reviewStatus && (
          <ProposalActionModal
            open={modalOpen}
            setOpen={setModalOpen}
            toggleOpenError={toggleOpenError}
            proposalId={proposalId}
            proposalName={proposalName}
            action={actionName}
            setIsApproved={setIsApproved}
            setLoading={setLoading}
          />
        )}
      </ButtonLine>
      <Toast
        open={openError}
        onClose={() => toggleOpenError(false)}
        type={ToastType.WARNING}
        title={formatMessage({id: 'proposal.saveDraft.error.title'})}
        details={formatMessage({id: 'proposal.changeStatus.error.description'})}
        duration={toastDuration}
        actionIcon={'close'}
        makeToastElRef={buttonRef as unknown as RefObject<HTMLButtonElement | HTMLElement>}
        el={document.querySelector('.spark-header__nav')}
        elHeader={document.querySelector('.spark-header__nav')}
      />
    </>
  );
};

const MessageContainer = styled.div`
  margin-left: 2rem;
`;

const buttonStyle = (color: string) => `
    background-color: ${color};
    border: ${color};
    box-shadow: inset 0 -2px 0 ${color};
`;

const deleteButtonStyle = () => `
    color: ${colors.red};
    border: solid 1px ${colors.red};
    background-color: ${colors.white};
    box-shadow: inset 0 -2px 0 ${colors.red};
`;

export const ButtonLine = styled.div`
  &.button-col {
    display: flex;
    flex-direction: column;
  }

  & .neutral {
    ${buttonStyle(colors.grey700)}
  }

  & .error {
    ${buttonStyle(colors.red)}
  }

  & .warning {
    ${buttonStyle(colors.warningYellow)}
  }

  & .delete {
    ${deleteButtonStyle()}
  }

  & .neutral:hover,
  & .error:hover,
  & .warning:hover,
  & .delete:hover {
    filter: brightness(93%);
  }
`;

export default ProposalViewButtons;
